import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const ranges = [
    { name: "monthly", cname: "monthly", value: "monthly" },
    { name: "weekly", cname: "weekly", value: "weekly" },
    { name: "daily", cname: "daily", value: "daily" },
    { name: "total", cname: "total", value: "total" },
];

const statuses = [
    { name: "active", cname: "active", value: "active" },
    { name: "pending", cname: "pending", value: "pending" },
    { name: "finished", cname: "finished", value: "finished" },
];

const booked_from_list = [
    { name: "airbnb", cname: "airbnb", value: "airbnb" },
    { name: "in person", cname: "in person", value: "in person" }
];

//there is an option alwaysOn for ReferenceInput
export const BookingsFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Property" source="property.id" reference="properties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer.id" reference="customers" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="start" />
        <AutocompleteInput
            source="status"
            choices={statuses}
            optionText="cname"
            optionValue="value"
        />
        <AutocompleteInput
            source="bookedFrom"
            choices={booked_from_list}
            optionText="cname"
            optionValue="value"
        />
    </Filter>
);

export const BookingsList = props => (
    <div>
        <ListGuesser {...props} filters={<BookingsFilter/>}>
            <ReferenceField label="Customer" source="customer" reference="customers">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Property" source="property" reference="properties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="start" />
            <FieldGuesser source="finish" />
            <FieldGuesser source="price" />
            <FieldGuesser source="status" />
            <FieldGuesser source="bookedFrom" />
            <BooleanField source="lightIncluded"/>
        </ListGuesser>
    </div>

);

export const BookingsCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="start" />
        <InputGuesser source="finish" />
        <InputGuesser source="price" />
        <InputGuesser source="lightIncluded" />
        <AutocompleteInput
            source="range_price"
            choices={ranges}
            optionText="cname"
            optionValue="value"
        />

        <AutocompleteInput
            source="status"
            choices={statuses}
            optionText="cname"
            optionValue="value"
        />
        <AutocompleteInput
            source="bookedFrom"
            choices={booked_from_list}
            optionText="cname"
            optionValue="value"
        />
    </CreateGuesser>
);

export const BookingsEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="customer"
            reference="customers"
            label="Customer"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="start" />
        <InputGuesser source="finish" />
        <InputGuesser source="price" />
        <InputGuesser source="lightIncluded" />
        <AutocompleteInput
            source="range_price"
            choices={ranges}
            optionText="cname"
            optionValue="value"
        />
        <AutocompleteInput
            source="status"
            choices={statuses}
            optionText="cname"
            optionValue="value"
        />
        <AutocompleteInput
            source="bookedFrom"
            choices={booked_from_list}
            optionText="cname"
            optionValue="value"
        />

    </EditGuesser>
);

import React from 'react';
import {ReferenceField, List, Datagrid, TextField, ReferenceInput, AutocompleteInput, TextInput, BooleanInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';


export const CustomersList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="email" />
            <FieldGuesser source="dni" />
            <FieldGuesser source="address" />
        </ListGuesser>
    </div>

);

export const CustomersCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="email" />
        <InputGuesser source="dni" />
        <InputGuesser source="address" />
    </CreateGuesser>
);

export const CustomersEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="email" />
        <InputGuesser source="dni" />
        <InputGuesser source="address" />
    </EditGuesser>
);
